<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Añade, edita y elimina administradores'">
      <template v-slot:actions>
        <v-btn v-show="permissionsBannerActions.create.includes(user.role)" @click="dialogCreateAdmin = true">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Data table admins -->
    <v-card class="my-8">
      <DataTable :headers="tableHeaders" :items="admins">
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/admins/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog create admin -->
    <DialogBox :model="dialogCreateAdmin">
      <template slot="toolbar">
        <span>Crear usuario administrador</span>
      </template>
      <template slot="content">
        <v-form ref="formCreateAdmin" v-model="formCreateAdminValid">
          <v-select
            v-model="admin.role"
            :items="['SUPERADMIN', 'ADMIN']"
            :rules="[(v) => !!v || 'Campo obligatorio']"
            label="Tipo de usuario"
          />
          <v-text-field v-model="admin.first_name" label="Nombre" :rules="formRules.textRules" />
          <v-text-field v-model="admin.last_name" label="Apellidos" :rules="formRules.textRules" />
          <CountryAutocomplete :countryId="admin.country" :editable="true" @selected="admin.country = $event" />
          <v-text-field v-model="admin.email" label="Email" :rules="formRules.emailRules" />
          <v-text-field v-model="admin.phone" label="Teléfono" :rules="formRules.phoneRules" />
          <v-text-field
            v-model="admin.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            label="Contraseña"
            :rules="formRules.passwordRequiredRules"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogCreateAdmin', 'formCreateAdmin')">Cerrar</v-btn>
        <v-btn text @click="create()">Crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import listeners from "@/firebase/listeners";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete";

export default {
  name: "Admins",
  components: {
    Banner,
    DataTable,
    DialogBox,
    CountryAutocomplete
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN"]
      },

      // Admins datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Nombre", value: "first_name", sortable: false },
        { text: "Apellidos", value: "last_name", sortable: false },
        { text: "Role", value: "role", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Dialogs
      dialogCreateAdmin: false,
      formCreateAdminValid: false,

      // Fields of user admin
      admin: {
        role: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: ""
      },
      showPassword: false
    };
  },
  computed: {
    ...mapState({
      admins: (state) => state.admins.admins,
      user: (state) => state.user.user
    })
  },
  async created() {
    await listeners.admins();
  },
  methods: {
    // Create admin
    async create() {
      if (this.$refs.formCreateAdmin.validate()) {
        console.log(this.admin);
        await this.$store.dispatch("admins/createAdmin", this.admin);
        this.closeDialog("dialogCreateAdmin", "formCreateAdmin");
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.admin = this.$options.data().admin;
    }
  }
};
</script>
